<template>
  <div class="map-page">
    <baidu-map
      class="map-container"
      :zoom="zoom"
      :center="location"
      :scroll-wheel-zoom="true"
      @ready="handleReady"
      :ak="BAI_DU_MAP_AK"
    >
      <bm-boundary
        :name="address_name"
        :strokeWeight="2"
        :fillOpacity="0.1"
        fillColor="#fff"
        strokeColor="#13c6de"
      />



    </baidu-map>

  </div>
</template>

<script>
import {BAI_DU_MAP_AK} from "../config/config";
import BaiduMap from "@/components/vue-baidu-map/components/map/Map"
import BmBoundary from "@/components/vue-baidu-map/components/others/Boundary.vue"
import mapStyleJSON from "@/views/home/components/map/mapStyle.json"

export default {
  name: "test",
  components: {
    BaiduMap,
    BmBoundary,
  },
  data() {
    return {
      BAI_DU_MAP_AK,
      markerList: [],
      pointList: [],
      map: null,
      location: { lng: 0, lat: 0 },
      zoom: 15,
      address_name: '西安市',
      timer: null,
      testData: {
        check_lng: "113.40349690755208",
        check_lat: "23.11317355685764",
        id: "1908",
      },
    }
  },

  mounted() {
    this.location.lng = 108.978804;
    this.location.lat = 34.347031;
  },

  methods: {


    handleReady({ map, BMap }) {
      this.map = map;
      this.location.lng = 108.978804;
      this.location.lat = 34.347031;
      this.zoom = 8;
      this.map.setMapStyleV2({
        styleId: "912c3bdb9b561674e1d1087fc122352e",
        styleJson: mapStyleJSON,
      });
    },

  },

}
</script>

<style scoped lang="scss">
.map-page {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #133067;

  .map-container {
    width: 100%;
    height: 100%;
    background-color: #133067;
  }

  .hint-info-0 {
    position: absolute;
    bottom: 3%;
    left: 3%;
    padding: 10px;
    background-color: rgba(10, 36, 88, .3);
    font-size: 12px;
    font-weight: 400;
    color: $mini-font-color;

    .hint-title {
      font-size: 14px;
    }



    p {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 6px;

      span:first-child {
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
    }

    .green span:first-child {
      background-color: #25f485;
    }
    .yellow span:first-child {
      background-color: #fcec06;
    }
    .orange span:first-child {
      background-color: #ff9314;
    }
    .red span:first-child {
      background-color: #f00;
    }
  }

  .hint-info-1 {
    @extend .hint-info-0;
    display: flex;
    align-items: center;

    span:first-child {
      width: 10px;
      height: 10px;
      background-color: #f00;
      border-radius: 50%;
    }

    span:last-child {
      margin-left: 10px;
      font-size: 12px;
      font-weight: 400;
      color: $mini-font-color;
    }
  }
}

::v-deep .anchorBL {
  display: none;
}
</style>
